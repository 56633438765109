<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
          ERROR
            </slot>
          </div>

          <div class="modal-body">
            {{getWarningMessage}}
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
              <button class="modal-default-button" @click="closeModal">
              OK
            </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

  export default {
    name: "src-pages-modals-errorModal-v1",
    data() {
      return {
        msg: "Geçici bir hata oluştu"
      }
    },

    methods: {
      closeModal() {
        this.$store.commit(this.types.DISABLE_MODAL);
      }
    },

    computed: {
      getWarningMessage() {
        return this.$store.state.activeModal.description;
      }
    }
  }
</script>
<style>

</style>
